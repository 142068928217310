import React from "react";
import {useMediaQuery} from '@mui/material';
import {
    Datagrid,
    List,
    SimpleList,
    TextField,
    Filter,
    TextInput,
    NumberField,
    ReferenceInput,
    SelectInput,
    Show,
    SimpleShowLayout,
    SimpleForm,
    Edit,
    EditButton,
    useRecordContext, useRefresh, useNotify
} from "react-admin";

import {JsonField, JsonInput} from "react-admin-json-view";
import Button from "@mui/material/Button";
import {apiClient} from "./Utils/Services/fetchService";

const LogsCallsFilter = (props) => (
    <Filter {...props} perPage={-1}>
        <TextInput label="Buscar..." source="q" alwaysOn/>
        <ReferenceInput label="Cliente" source="id_clientes" reference="clientes" allowEmpty perPage={-1}
                        sort={{field: 'nombre', order: 'ASC'}}>
            <SelectInput optionText="nombre"/>
        </ReferenceInput>
    </Filter>
);


export const LogsCallsShow = (props) => {

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <NumberField source="id"/>
                <TextField label="Fecha" source="createdAt"/>
                <TextField label="Error" source="error"/>
                <TextField label="Tipo" source="type"/>
                <JsonField source="email" jsonString={false}
                           reactJsonOptions={{
                               name: "Email",
                               collapsed: true,
                               enableClipboard: true,
                           }}></JsonField>
            </SimpleShowLayout>
        </Show>
    );
};

const ResendEmailButton = (props) => {

    const refresh = useRefresh();
    const record = useRecordContext();
    const notify = useNotify();
    const handleClick = async (data) => {
        const urlAPI = `${process.env.REACT_APP_API_URL}lt`;

        let response = await apiClient.post(`${urlAPI}/logs/resend/${record.id}`, {method: 'POST'})
            .then(response => response.json());

        if (!response['success']) {
            notify(`Error en el reenvio de email #${record.id}, ${record.message}`, {
                autoHideDuration: 5000,
                type: "error"
            });
        } else {
            notify(`Se ha enviado el email #${record.id}`, {autoHideDuration: 5000, type: "success"});
        }

    };

    return <Button className="classes.btn_deleted" onClick={handleClick} {...props} >Reenvíar email</Button>;
};

export const LogsCallsList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List filters={<LogsCallsFilter/>}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.nombre}
                    secondaryText={record => record.id}
                />
            ) : (
                <Datagrid>
                    <NumberField source="id"/>
                    <TextField label="Fecha" source="fecha"/>
                    <TextField label="Process" source="process"/>
                    <TextField label="Fecha llamada" source="fechaLlamada"/>
                    <TextField label="Tipo registro" source="tipo_registro"/>
                    <TextField label="Registro" source="registro_id"/>
                    <TextField label="Teléfono Emisor" source="telefono_emisor"/>
                    <TextField label="Teléfono Receptor" source="telefono_receptor"/>
                    <TextField label="Teléfono" source="telefono"/>
                    <TextField label="Cliente" source="cliente"/>
                    <TextField label="Servicio" source="servicio"/>
                    <JsonField source="data" jsonString={false} // Set to true if the value is a string, default: false
                               reactJsonOptions={{
                                   // Props passed to react-json-view
                                   name: "Data",
                                   collapsed: true,
                                   enableClipboard: true,
                                   // displayDataTypes: false,
                               }}></JsonField>
                    <JsonField source="response"
                               jsonString={false} // Set to true if the value is a string, default: false
                               reactJsonOptions={{
                                   // Props passed to react-json-view
                                   name: "Response",
                                   collapsed: true,
                                   enableClipboard: true,
                                   // displayDataTypes: false,
                               }}></JsonField>

                    <EditButton/>
                </Datagrid>
            )}
        </List>
    );
}

export const LogsEdit = props => (
    <Edit {...props}>
        <SimpleForm>

            <JsonInput source="email" jsonString={false} // Set to true if the value is a string, default: false
                       reactJsonOptions={{
                           // Props passed to react-json-view
                           name: "Email",
                           collapsed: true,
                           enableClipboard: true,
                           // displayDataTypes: false,
                       }}></JsonInput>
        </SimpleForm>
    </Edit>
);