import React, {useState} from 'react';
import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    BooleanField,
    Filter,
    TextInput,
    ReferenceField,
    SingleFieldList,
    ChipField,
    ReferenceArrayField,
    Show,
    SimpleShowLayout,
    ShowButton,
    FunctionField, ReferenceInput, SelectInput, DateInput, Button, useNotify, useRefresh, useUpdate
} from 'react-admin';
import {useMediaQuery} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import {CopyData} from "./App";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import {apiClient} from "./Utils/Services/fetchService";
import DialogTitle from "@mui/material/DialogTitle";
import {findDOMNode} from "react-dom";

const urlAPI = `${process.env.REACT_APP_API_URL}`;

const ConcesionariosFilter = (props) => (
    <Filter {...props} perPage={-1}>
        <TextInput label="Buscar..." source="q" alwaysOn/>

        <ReferenceInput source='id_clientes' reference='clientes' allowEmpty perPage={-1}
                        sort={{field: 'nombre', order: 'ASC'}} alwaysOn>
            <SelectInput label="Cliente" optionText="nombre"/>
        </ReferenceInput>

        <SelectInput
            label="Estado"
            source="estado"
            choices={[
                {id: 'activo', name: 'Activo'},
                {id: 'inactivo', name: 'Inactivo'},
            ]}
            alwaysOn
        />

        <DateInput label="Fecha de alta" source="fecha_registro" alwaysOn/>

    </Filter>
);

function actualizarSedesConcesionario(id) {

    return apiClient.get(`${urlAPI}lt/clientes/updateSedesConcesionario/${id}`, {method: 'GET'})
        .then(response => response.json())
        .then(data => {
            if (data.names && data.names.length > 0) {
                alert(`Sedes actualizadas:\n- ${data.names.join('\n- ')}`);
            } else {
                alert('No se encontraron sedes a actualizar.');
            }
            return data;
        })
        .catch(error => {
            console.error('Error al actualizar las sedes:', error);
            alert('Hubo un error al actualizar las sedes.');
            throw error;
        });

}

export const ConcesionariosShow = () => {
    const [open, setOpen] = useState(false);
    const [currentRecordId, setCurrentRecordId] = useState(null);

    const handleClickOpen = (recordId) => {
        setCurrentRecordId(recordId);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentRecordId(null);
    };

    const handleConfirm = () => {
        if (currentRecordId) {
            actualizarSedesConcesionario(currentRecordId);
        }
        handleClose();
    };

    return (
        <Show>
            <SimpleShowLayout>
                <TextField source="id" label="ID"/>
                <TextField source="nombre"/>
                <ReferenceField label="Cliente" source="cliente" reference="clientes" link="show">
                    <FunctionField render={(record) => (
                        <ChipField source="nombre"
                                   color={record.activo ? 'success' : 'error'}
                                   icon={record.activo ? <DoneIcon/> : <ClearIcon/>}/>
                    )}/>
                </ReferenceField>
                <ReferenceArrayField reference="sedes" source="sedes" link="show">
                    <SingleFieldList linkType="show">
                        <FunctionField render={(record) => (
                            <ChipField source="nombre"
                                       color={record.activo ? 'success' : 'error'}
                                       icon={record.activo ? <DoneIcon/> : <ClearIcon/>}/>
                        )}/>
                    </SingleFieldList>
                </ReferenceArrayField>

                <TextField source="masterid" label="Master ID"/>
                <TextField source="fechaAlta" label="Fecha de alta"/>
                <TextField source="fechaBaja" label="Fecha de baja"/>
                <BooleanField source="activo"/>
                <BooleanField source="borrado"/>
                <TextField source="code" label="Código de cliente"/>
                <TextField source="web" label="Web"/>
                <TextField source="direccion" label="Dirección"/>
                <TextField source="imagen" label="Imagen"/>
                <TextField source="telefono" label="Teléfono"/>
                <TextField source="descripcion" label="Descripción"/>
                <TextField source="logotipo" label="Logotipo"/>
                <TextField source="horario" label="Horario"/>
                <TextField source="rgpd" label="RGPD Concesionario"/>


                <FunctionField
                    render={(record) => (
                        <Button
                            label=""
                            onClick={() => handleClickOpen(record.id)}
                            sx={{
                                backgroundColor: 'primary.main',
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: '0.875rem',
                                padding: '6px 8px',
                                minWidth: '64px',
                                '&:hover': {
                                    backgroundColor: 'primary.dark',
                                },
                                '& .MuiButton-startIcon': {marginRight: 0},
                            }}
                        >
                            Actualizar sedes
                        </Button>
                    )}
                />

                {/*<LoadData typeData="conce"/>*/}

                <Dialog open={open} onClose={handleClose}>
                    <DialogContent>
                        <DialogContentText>
                            Esta acción insertará nuevas sedes en base a lo que se encuentre en Maxterauto.
                            ¿Continuar?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancelar
                        </Button>
                        <Button
                            onClick={handleConfirm}
                            color="primary"
                            variant="contained"
                            autoFocus
                        >
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>
            </SimpleShowLayout>
        </Show>
    );
};

const ToggleActivoField = ({record}) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const [update] = useUpdate();

    const handleClick = (e) => {
        e.stopPropagation();
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleConfirm = async () => {
        setLoading(true);
        const newValue = !record.activo;

        try {
            // Usando la misma estructura que usas en otros endpoints
            const response = await apiClient.post(
                `${process.env.REACT_APP_API_URL}lt/concesionarios/${record.id}/toggle-activo`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        activo: newValue,
                        concesionarioId: record.id
                    })
                }
            );

            if (!response.ok) {
                throw new Error('Error al actualizar el estado del concesionario');
            }

            notify(
                newValue
                    ? `Concesionario ${record.nombre || record.id} activado correctamente`
                    : `Concesionario ${record.nombre || record.id} desactivado correctamente`,
                {type: 'success'}
            );

            refresh(); // Refresca la lista para mostrar el cambio
        } catch (error) {
            console.error('Error al cambiar estado:', error);
            notify(
                `Error al ${newValue ? 'activar' : 'desactivar'} el concesionario: ${error.message}`,
                {type: 'error'}
            );
        } finally {
            setOpen(false);
        }
    };


    return (
        <>
            <span
                style={{
                    color: record.activo ? 'inherit' : 'red',
                    cursor: 'pointer'
                }}
                onClick={handleClick}
            >
                {record.activo ? <DoneIcon color="success"/> : <ClearIcon color="error"/>}
            </span>

            <Dialog open={open} onClose={handleCancel}>
                <DialogTitle>Confirmar cambio de estado</DialogTitle>
                <DialogContent>
                    <p>
                        ¿Estás seguro de que quieres {record.activo ? 'desactivar' : 'activar'} el
                        concesionario {record.nombre || record.id}?
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleConfirm} color="primary">
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export const ConcesionariosList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List filters={<ConcesionariosFilter/>}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.nombre}
                    secondaryText={record => record.id}
                    tertiaryText={record => record.activo}
                />
            ) : (
                <Datagrid>

                    <div label="ID">
                        <TextField source="id"/>
                        <CopyData field="id"></CopyData>
                    </div>

                    <div label="Nombre">
                        <TextField source="nombre"/>
                        <CopyData field="nombre"></CopyData>
                    </div>

                    <div label="Master ID">
                        <TextField source="masterid"/>
                        <CopyData field="masterid"></CopyData>
                    </div>

                    <FunctionField
                        source="activo"
                        render={record => <ToggleActivoField record={record}/>}
                    />

                    <div label="Fecha de alta">
                        <TextField source="fechaAlta"/>
                    </div>

                    <ShowButton/>
                </Datagrid>
            )}
        </List>
    );
};