import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    Filter
} from 'react-admin';
import {JsonField} from "react-admin-json-view";

const FailedWebhookFilter = (props) => (
    <Filter {...props} perPage={-1}>
        <TextInput label="Buscar..." source="q" alwaysOn/>
    </Filter>
);

export const FailedWebhookList = () => {

    return (
        <List filters={<FailedWebhookFilter/>} sort={{field: 'id', order: 'DESC'}}>
            <Datagrid>
                <TextField source="createdAt" label="Fecha"/>
                <TextField source="error" color={'error'}/>
                <TextField source="inbox"/>
                <JsonField label="Data" source="data" jsonString={false}
                           reactJsonOptions={{
                               name: "Message",
                               collapsed: true,
                               enableClipboard: true,
                           }}
                ></JsonField>
            </Datagrid>
        </List>
    );
};
