import React from 'react';
import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    TextInput,
    Filter
} from 'react-admin';
import {useMediaQuery} from '@mui/material';

const FieldsLogFilter = (props) => (
    <Filter {...props} perPage={-1}>
        <TextInput label="Search" source="q" alwaysOn/>
    </Filter>
);

export const FieldsLogList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List title="Lista de Keys" filters={<FieldsLogFilter/>}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.key}
                    secondaryText={record => record.value}
                />
            ) : (

                <Datagrid>
                    <TextField source="id" label="ID"/>
                    <TextField source="key" label="Dato"/>
                    <TextField source="value" label="Valor"/>
                </Datagrid>

            )}
        </List>
    );
};
