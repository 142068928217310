// fetchService.js

// Configuración base
const API_URL = process.env.REACT_APP_API_URL;
const LEADTRACKING_TOKEN = process.env.REACT_APP_LEADTRACKING_TOKEN ?? '';

// Interceptor para fetch
export const fetchInterceptor = async (url, options = {}) => {
    // Configuración por defecto
    const defaultOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${LEADTRACKING_TOKEN}`,

        },
        mode: 'cors'
    };

    // Mezclamos las opciones por defecto con las proporcionadas
    const finalOptions = {
        ...defaultOptions,
        ...options,
        headers: {
            ...defaultOptions.headers,
            ...options.headers,
        },
    };

    // Si la URL no empieza con http, añadimos la URL base
    const finalUrl = url.startsWith('http') ? url : `${API_URL}${url}`;

    return fetch(finalUrl, finalOptions);
};

// Métodos helper para las operaciones comunes
export const apiClient = {
    get: (url, options = {}) => {
        return fetchInterceptor(url, {
            ...options,
            method: 'GET',
        });
    },

    post: (url, data, options = {}) => {
        return fetchInterceptor(url, {
            ...options,
            method: 'POST',
            body: data.body,
        });
    },

    put: (url, data, options = {}) => {
        return fetchInterceptor(url, {
            ...options,
            method: 'PUT',
            body: data.body,
        });
    },

    delete: (url, options = {}) => {
        return fetchInterceptor(url, {
            ...options,
            method: 'DELETE',
        });
    },
};