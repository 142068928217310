import React, {useState} from 'react';
import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    BooleanField,
    Filter,
    TextInput,
    ReferenceField,
    Show,
    SimpleShowLayout,
    ShowButton,
    FunctionField,
    ChipField, SelectInput, ReferenceInput, DateInput, useNotify, useRefresh, useUpdate, Button
} from 'react-admin';
import {useMediaQuery} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import {CopyData} from "./App";
import {apiClient} from "./Utils/Services/fetchService";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

const SedesFilter = (props) => (
    <Filter {...props} perPage={-1}>
        <TextInput label="Buscar..." source="q" alwaysOn/>

        <ReferenceInput source='id_concesionario' reference='concesionarios' allowEmpty perPage={-1}
                        sort={{field: 'nombre', order: 'ASC'}} alwaysOn>
            <SelectInput label="Concesionario" optionText="nombre"/>
        </ReferenceInput>

        <SelectInput
            label="Estado"
            source="estado"
            choices={[
                {id: 'activo', name: 'Activo'},
                {id: 'inactivo', name: 'Inactivo'},
            ]}
            alwaysOn
        />

        <DateInput label="Fecha de alta" source="fecha_registro" alwaysOn/>

    </Filter>
);
export const SedesShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="id" label="ID"/>
            <TextField source="nombre"/>
            <ReferenceField label="Cliente" source="cliente" reference="clientes" link="show">
                <FunctionField render={(record) => (
                    <ChipField source="nombre"
                               color={record.activo ? 'success' : 'error'}
                               icon={record.activo ? <DoneIcon/> : <ClearIcon/>}/>
                )}/>
            </ReferenceField>
            <ReferenceField label="Concesionario" source="concesionario" reference="concesionarios" link="show">
                <FunctionField render={(record) => (
                    <ChipField source="nombre"
                               color={record.activo ? 'success' : 'error'}
                               icon={record.activo ? <DoneIcon/> : <ClearIcon/>}/>
                )}/>
            </ReferenceField>
            <TextField source="masterid" label="Master ID"/>
            <TextField source="fechaAlta" label="Fecha de alta"/>
            <TextField source="fechaBaja" label="Fecha de baja"/>
            <BooleanField source="activo"/>
            <BooleanField source="borrado"/>
            <TextField source="web" label="Web"/>
            <TextField source="direccion" label="Dirección"/>
            <TextField source="telefono" label="Teléfono"/>
            <TextField source="descripcion" label="Descripción"/>

        </SimpleShowLayout>
    </Show>
);


const ToggleActivoField = ({record}) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const [update] = useUpdate();

    const handleClick = (e) => {
        e.stopPropagation();
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleConfirm = async () => {
        setLoading(true);
        const newValue = !record.activo;

        try {
            // Usando la misma estructura que usas en otros endpoints
            const response = await apiClient.post(
                `${process.env.REACT_APP_API_URL}lt/sedes/${record.id}/toggle-activo`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        activo: newValue,
                        concesionarioId: record.id
                    })
                }
            );

            if (!response.ok) {
                throw new Error('Error al actualizar el estado de la sede');
            }

            notify(
                newValue
                    ? `Sede ${record.nombre || record.id} activada correctamente`
                    : `Sede ${record.nombre || record.id} desactivada correctamente`,
                {type: 'success'}
            );

            refresh(); // Refresca la lista para mostrar el cambio
        } catch (error) {
            console.error('Error al cambiar estado:', error);
            notify(
                `Error al ${newValue ? 'activar' : 'desactivar'} la sede: ${error.message}`,
                {type: 'error'}
            );
        } finally {
            setOpen(false);
        }
    };


    return (
        <>
            <span
                style={{
                    color: record.activo ? 'inherit' : 'red',
                    cursor: 'pointer'
                }}
                onClick={handleClick}
            >
                {record.activo ? <DoneIcon color="success"/> : <ClearIcon color="error"/>}
            </span>

            <Dialog open={open} onClose={handleCancel}>
                <DialogTitle>Confirmar cambio de estado</DialogTitle>
                <DialogContent>
                    <p>
                        ¿Estás seguro de que quieres {record.activo ? 'desactivar' : 'activar'} la
                        sede {record.nombre || record.id}?
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleConfirm} color="primary">
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export const SedesList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List filters={<SedesFilter/>}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.nombre}
                    secondaryText={record => record.id}
                    tertiaryText={record => record.activo}
                />
            ) : (
                <Datagrid>

                    <div label="ID">
                        <TextField source="id"/>
                        <CopyData field="id"></CopyData>
                    </div>

                    <div label="Nombre">
                        <TextField source="nombre"/>
                        <CopyData field="nombre"></CopyData>
                    </div>

                    <div label="Master ID">
                        <TextField source="masterid"/>
                        <CopyData field="masterid"></CopyData>
                    </div>

                    <FunctionField
                        source="activo"
                        render={record => <ToggleActivoField record={record}/>}
                    />

                    <ReferenceField label="Concesionario" source="concesionario" reference="concesionarios" link="show">
                        <FunctionField render={(record) => (
                            <ChipField source="nombre"
                                       color={record.activo ? 'success' : 'error'}
                                       icon={record.activo ? <DoneIcon/> : <ClearIcon/>}/>
                        )}/>
                    </ReferenceField>
                    <ReferenceField label="Cliente" source="cliente" reference="clientes" link="show">
                        <FunctionField render={(record) => (
                            <ChipField source="nombre"
                                       color={record.activo ? 'success' : 'error'}
                                       icon={record.activo ? <DoneIcon/> : <ClearIcon/>}/>
                        )}/>
                    </ReferenceField>
                    <ShowButton/>
                </Datagrid>
            )}
        </List>
    );
};