import {
    SimpleList,
    Datagrid,
    TextField,
    Show,
    SimpleShowLayout,
    EditButton,
    useRecordContext,
    TopToolbar,
    FilterButton,
    CreateButton,
    ReferenceInput,
    SelectInput,
    ListBase,
    FilterForm,
    Pagination,
    Edit,
    SimpleForm,
    TextInput,
} from 'react-admin';
import {useMediaQuery, Stack} from '@mui/material';

import {BillingEditDialog} from "./Utils/Dialogs/BillingEditDialog";
import React from "react";
import {CopyData} from "./App";

const BillingsTitle = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <span>Facturación: {record.cif}</span>;
}

const billingsFilters = [
    <TextInput label="Buscar..." source="q" alwaysOn/>,
    <ReferenceInput label='Facturación' source='id' reference='billings' allowEmpty perPage={-1}
                    sort={{field: 'cif', order: 'ASC'}}>
        <SelectInput optionText="cif"/>
    </ReferenceInput>
];

const BillingsListToolbar = () => (
    <Stack direction="row" justifyContent="space-between">
        <FilterForm filters={billingsFilters}/>
        <div>
            <FilterButton filters={billingsFilters}/>
            <CreateButton/>
        </div>
    </Stack>
)

const BillingsShowActions = () => (
    <TopToolbar>
        <EditButton/>
    </TopToolbar>
);

export const BillingsShow = () => (
    <Show title={<BillingsTitle/>} actions={<BillingsShowActions/>}>
        <SimpleShowLayout>
            <TextField source="id"/>
        </SimpleShowLayout>
    </Show>
);

export const BillingsList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <ListBase>
            <BillingsListToolbar/>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.company_cif}
                    secondaryText={record => record.id}
                    tertiaryText={record => record.activo}
                />
            ) : (
                <Datagrid>

                    <div label="ID">
                        <TextField source="id"/>
                        <CopyData field="id"></CopyData>
                    </div>

                    <div label="Cliente">
                        <TextField source="cliente"/>
                        <CopyData field="cliente"></CopyData>
                    </div>

                    <div label="Concesionario">
                        <TextField source="concesionario"/>
                        <CopyData field="concesionario"></CopyData>
                    </div>

                    <div label="Razón Social">
                        <TextField source="company_name"/>
                        <CopyData field="company_name"></CopyData>
                    </div>

                    <div label="CIF">
                        <TextField source="company_cif"/>
                        <CopyData field="company_cif"></CopyData>
                    </div>

                    <div label="Dirección">
                        <TextField source="fiscal_address"/>
                        <CopyData field="fiscal_address"></CopyData>
                    </div>

                    <div label="Contacto">
                        <TextField source="contact_name"/>
                        <CopyData field="contact_name"></CopyData>
                    </div>

                    <div label="Email">
                        <TextField source="contact_email"/>
                        <CopyData field="contact_email"></CopyData>
                    </div>

                    <div label="Teléfono">
                        <TextField source="contact_phone"/>
                        <CopyData field="contact_phone"></CopyData>
                    </div>

                    <div label="Activo">
                        <BillingEditDialog action="enableBilling"/>
                    </div>

                    <div label="Creado">
                        <TextField source="created_at"/>
                        <CopyData field="created_at"></CopyData>
                    </div>

                    <EditButton/>

                </Datagrid>
            )}
            <Pagination/>
        </ListBase>
    );

};

export const BillingsEdit = props => (
    <Edit {...props}>
        <SimpleForm>

            <TextInput label="ID" source="id" disabled/>
            <TextInput label="Razón Social" source="company_name"/>
            <TextInput label="CIF" source="company_cif"/>
            <TextInput label="Dirección" source="fiscal_address"/>
            <TextInput label="Contacto" source="contact_name"/>
            <TextInput label="Email" source="contact_email"/>
            <TextInput label="Teléfono" source="contact_phone"/>

        </SimpleForm>
    </Edit>
);
