import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    Create,
    TextInput,
    ReferenceInput,
    BooleanInput,
    SelectInput,
    SimpleForm
} from 'react-admin';

export const CategoriasTiposList = props => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" label="ID"/>
            <TextField source="nombre" label="Nombre" sortable={false}/>
            <ReferenceField source="categoria" reference="categoriasTipos" label="Categoría padre" sortable={false}>
                <TextField source="nombre"/>
            </ReferenceField>
        </Datagrid>
    </List>
);

export const CategoriasTiposCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Nombre" source="nombre"/>
            <ReferenceInput source="id_parent" reference="categoriasTipos" label="Categoría padre" perPage={-1}
                            filter={{is_parent: 1}}>
                <SelectInput optionText="nombre"/>
            </ReferenceInput>
            <BooleanInput label="Tiene ID externo" source="has_id"/>
            <TextInput label="Nombre ID externo" source="name_field_id"/>
        </SimpleForm>
    </Create>
);
