import React, {useState} from 'react';
import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    TextInput,
    ReferenceArrayField,
    SingleFieldList,
    Show,
    SimpleShowLayout,
    ShowButton,
    FunctionField,
    ArrayField,
    useRedirect,
    useRecordContext,
    useRefresh, TopToolbar, Filter, SelectInput, DateInput
} from 'react-admin';
import {useMediaQuery} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import Button from "@mui/material/Button";
import Chip from '@mui/material/Chip';
import {CopyData} from './App';
import {apiClient} from "./Utils/Services/fetchService";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

const ClientesFilter = (props) => (
    <Filter {...props} perPage={-1}>
        <TextInput label="Buscar..." source="q" alwaysOn/>

        <SelectInput
            label="Estado"
            source="estado"
            choices={[
                {id: 'activo', name: 'Activo'},
                {id: 'inactivo', name: 'Inactivo'},
            ]}
            alwaysOn
        />

        <DateInput label="Fecha de alta" source="fecha_registro" alwaysOn/>

    </Filter>
);


const urlAPI = `${process.env.REACT_APP_API_URL}`;

function replicarTiposCliente(id) {

    let requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    apiClient.get(`${urlAPI}replicar/tipos/leads/cliente/${id}`, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));

}

function actualizarConcesionariosCliente(id) {

    return apiClient.get(`${urlAPI}lt/clientes/updateDealersClient/${id}`, {method: 'GET'})
        .then(response => response.json())
        .then(data => {
            if (data.names && data.names.length > 0) {
                alert(`Concesionarios actualizados:\n- ${data.names.join('\n- ')}`);
            } else {
                alert('No se encontraron concesionarios a actualizar.');
            }
            return data;
        })
        .catch(error => {
            console.error('Error al actualizar los concesionarios:', error);
            alert('Hubo un error al actualizar los concesionarios.');
            throw error;
        });
}

const DashboardButton = (props) => {
    const record = useRecordContext();
    const redirect = useRedirect();

    if (!record) return null;

    const handleClick = () => {
        redirect('/categoriasLicencias/' + record.id);
    };

    const nameButton = record.new ? 'Crear' : 'Editar';

    return (
        <div style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
            <Button className="classes.btn_create" onClick={handleClick} {...props}>
                {nameButton}
            </Button>
            {!record.new && <DeletedButton record={record}/>}
        </div>
    );
};

const DeletedButton = (props) => {

    const refresh = useRefresh();
    const record = useRecordContext();
    const handleClick = async () => {

        let response = await apiClient.delete(`${urlAPI}lt/categoriasLicencias/${record.id}`)
            .then(response => response.json());

        if (response) {
            refresh();
        }

    };

    return <Button className="classes.btn_deleted" onClick={handleClick} {...props} >Eliminar</Button>;
};


export const ClientesShow = () => {
    const [open, setOpen] = useState(false);
    const [currentRecordId, setCurrentRecordId] = useState(null);

    const handleClickOpen = (recordId) => {
        setCurrentRecordId(recordId);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentRecordId(null);
    };

    const handleConfirm = () => {
        if (currentRecordId) {
            actualizarConcesionariosCliente(currentRecordId);
        }
        handleClose();
    };

    return (
        <Show>
            <SimpleShowLayout>

                <TextField source="nombre"/>
                <TextField source="api_token" label="API Token"/>
                <TextField source="fechaAlta" label="Fecha de alta"/>

                <FunctionField
                    source="activo"
                    render={record => (
                        <span style={{color: record.activo ? 'inherit' : 'red'}}>
                                {record.activo ? <DoneIcon color="success"/> : <ClearIcon color="error"/>}
                            </span>
                    )}
                />
                <ReferenceArrayField label='Concesionarios' reference='concesionarios' source='concesionarios'>
                    <SingleFieldList linkType="show">
                        <FunctionField render={(record) => {
                            if (!record) return null;
                            const marcas = record.marcas ? ` (${record.marcas})` : '';
                            const label = `${record.nombre}${marcas}`;
                            return (
                                <Chip
                                    label={label}
                                    variant="outlined"
                                    color={record.activo ? 'success' : 'error'}
                                />
                            );
                        }}/>
                    </SingleFieldList>
                </ReferenceArrayField>

                <TextField source="codigoCliente" label="Código de cliente"/>
                <TextField source="numSms" label="Número de SMS contratados"/>
                <TextField source="codigoCliente" label="Código de cliente"/>
                <TextField source="regEventosControl" label="Eventos de control"/>
                <TextField source="countryId" label="Country"/>
                <TextField source="timeZone" label="Time Zone"/>
                <TextField source="rgpd" label="RGPD Cliente"/>

                <FunctionField
                    render={(record) => (
                        <Button
                            label="Actualizar concesionarios"
                            onClick={() => handleClickOpen(record.id)}
                            sx={{
                                backgroundColor: 'primary.main',
                                color: '#fff',
                                fontWeight: 'bold',
                                '&:hover': {
                                    backgroundColor: 'primary.dark',
                                },
                            }}
                        >
                            Actualizar concesionarios
                        </Button>
                    )}
                />

                <FunctionField
                    render={(record) => (
                        <Button
                            label="Replicar tipos de leads"
                            onClick={() => replicarTiposCliente(record.id)}
                            sx={{
                                backgroundColor: 'primary.main',
                                color: '#fff',
                                fontWeight: 'bold',
                                '&:hover': {
                                    backgroundColor: 'primary.dark',
                                },
                            }}
                        >
                            Replicar tipos de leads
                        </Button>
                    )}
                />

                {/*<LoadData typeData="client"/>*/}

                <h1>Listado de estados del cliente</h1>
                <ArrayField label="Estados - Licencias" source="states">
                    <Datagrid>
                        <TextField source="idState" label="ID"/>
                        <TextField source="state.name" label="Nombre"/>
                        <FunctionField
                            label="Roles"
                            render={record =>
                                record.roles.map(role => (
                                    <Chip
                                        key={role}
                                        label={role}
                                        style={{marginRight: '5px', marginBottom: '5px'}}
                                    />
                                ))
                            }
                        />
                        <DashboardButton/>
                    </Datagrid>
                </ArrayField>

                <Dialog open={open} onClose={handleClose}>
                    <DialogContent>
                        <DialogContentText>
                            Esta acción insertará nuevos concesionarios en base a lo que se encuentre en Maxterauto.
                            ¿Continuar?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={handleConfirm} color="primary" variant="contained" autoFocus>
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>
            </SimpleShowLayout>
        </Show>
    );
};

const ListActions = () => (
    <TopToolbar>
        {/*<ClientReportButton/>*/}
    </TopToolbar>
);


export const ClientesList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List filters={<ClientesFilter/>} actions={<ListActions/>}>

            {isSmall ? (
                <SimpleList
                    primaryText={record => record.nombre}
                    secondaryText={record => record.id}
                    tertiaryText={record => record.activo}
                />
            ) : (
                <Datagrid>
                    <div label="ID">
                        <TextField source="id"/>
                        <CopyData field="id"></CopyData>
                    </div>

                    <div label="Nombre">
                        <FunctionField
                            source="nombre"
                            render={record => (
                                <span style={{color: record.activo ? 'inherit' : 'red'}}>
                                    {record.nombre}
                                </span>
                            )}
                        />
                        <CopyData field="nombre"></CopyData>
                    </div>

                    <FunctionField
                        source="activo"
                        render={record => (
                            <span style={{color: record.activo ? 'inherit' : 'red'}}>
                                {record.activo ? <DoneIcon color="success"/> : <ClearIcon color="error"/>}
                            </span>
                        )}
                    />

                    <div label="Fecha de alta">
                        <TextField source="fechaAlta"/>
                    </div>

                    <ShowButton/>
                </Datagrid>
            )}
        </List>
    );
};

const downloadClientReport = async () => {
    try {
        const response = await apiClient.post(`${process.env.REACT_APP_API_URL}clientes/export`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/xlsx',
            }
        });

        if (!response.ok) {
            throw new Error('Error al descargar el informe');
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const now = new Date();
        const year = now.getFullYear().toString().slice(-2);
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const formattedDate = `${year}${month}${day}`;
        const fileDownloadName = `USERS - INFORME USUARIOS POR CLIENTE ${formattedDate}.xlsx`;
        link.setAttribute('download', fileDownloadName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (error) {
        console.error('Error:', error);
    }
};

const ClientReportButton = () => (
    <Button
        onClick={downloadClientReport}
        size={'small'}
    >
        Informe
    </Button>
);
