import React from 'react';
import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    Create,
    Filter,
    BooleanInput
} from 'react-admin';
import {useMediaQuery} from '@mui/material';
import {CopyData} from "./App";

const TiposFilter = (props) => (
    <Filter {...props} perPage={-1}>
        <TextInput label="Buscar..." source="q" alwaysOn/>
        <ReferenceInput label="Cliente" source="id_clientes" reference="clientes" allowEmpty perPage={-1} alwaysOn>
            <SelectInput label="Cliente" optionText="nombre"/>
        </ReferenceInput>
        <ReferenceInput label="CategoriasTipos" source="id_category_leads_tipos" reference="categoriasTipos" allowEmpty
                        perPage={-1} alwaysOn>
            <SelectInput label="Categoría" optionText="nombre"/>
        </ReferenceInput>
        <SelectInput
            label="País"
            source="country"
            alwaysOn
            choices={[
                {id: 'ES', name: 'España'},
                {id: 'IT', name: 'Italia'},
                {id: 'PT', name: 'Portugal'}
            ]}
        />
    </Filter>
);

const TiposTitle = ({record}) => {
    return <span>Tipo {record ? `"${record.title}"` : ''}</span>;
};

export const TiposList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List title="Lista de Tipos de leads" filters={<TiposFilter/>}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.nombre_mail}
                    secondaryText={record => record.categoriasTipos}
                    tertiaryText={record => record.clientes}
                />
            ) : (

                <Datagrid>
                    <TextField source="id" label="ID"/>
                    <ReferenceField source="clientes_id" reference="clientes" label="Cliente" allowEmpty>
                        <TextField source="nombre"/>
                    </ReferenceField>

                    <div label="zap_name">
                        <TextField source="nombre" label="Zap Name"/>
                        <CopyData field="nombre"></CopyData>
                    </div>

                    <div label="Nombre">
                        <TextField source="nombreMail" label="Nombre"/>
                        <CopyData field="nombreMail"></CopyData>
                    </div>

                    <ReferenceField source="id_category_leads_tipos" reference="categoriasTipos" label="Categoría"
                                    filter={{is_parent: 0}} sortable={false} allowEmpty>
                        <TextField source="nombre"/>
                    </ReferenceField>
                    <EditButton/>
                </Datagrid>

            )}
        </List>
    );
};

export const TiposEdit = props => (
    <Edit title={<TiposTitle/>} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" label="ID"/>
            <ReferenceInput source="clientes_id" reference="clientes" perPage={-1}>
                <SelectInput optionText="nombre" label="Cliente"/>
            </ReferenceInput>
            <TextInput source="nombre" label="zap_name"/>
            <TextInput source="nombre_mail" label="Nombre"/>
            <ReferenceInput source="id_category_leads_tipos" reference="categoriasTipos" label="Categoría" perPage={-1}
                            filter={{is_parent: 0}}>
                <SelectInput optionText="nombre" label="Categoría"/>
            </ReferenceInput>
            <BooleanInput label="Manual" source="manual"/>
            <BooleanInput label="Zapier" source="zapier"/>
            <BooleanInput label="Ventas" source="ventas"/>
        </SimpleForm>
    </Edit>
);

export const TiposCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <ReferenceInput source="clientes_id" reference="clientes" label="Cliente" perPage={-1}>
                <SelectInput optionText="nombre"/>
            </ReferenceInput>
            <TextInput source="nombre" label="Zap Name"/>
            <TextInput source="nombre_mail" label="Nombre"/>
            <ReferenceInput source="id_category_leads_tipos" reference="categoriasTipos" label="Categoría" perPage={-1}>
                <SelectInput optionText="nombre"/>
            </ReferenceInput>
            <BooleanInput label="Manual" source="manual"/>
            <BooleanInput label="Zapier" source="zapier"/>
            <BooleanInput label="Ventas" source="ventas"/>
        </SimpleForm>
    </Create>
);