import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

const Dashboard = () => {

    //let userData = JSON.parse(localStorage.getItem('userData'));
    //const isSuperAdmin = userData.roles.includes('ROLE_SUPER_ADMIN');

    return (
        <Card>
            {/* Comentario: CardHeader que da la bienvenida */}
            <CardHeader title="Bienvenido a Leadtracking Matching"/>

            {/* Comentario: Muestra el CardContent y el botón solo si el usuario es un super administrador */}
            {/*isSuperAdmin && */(
                <>
                    {/* Comentario: CardContent que describe el servicio */}
                    <CardContent>Servicio para el control de acceso de los nombres de los clientes para insertar leads
                        en los sistemas correspondientes.</CardContent>
                </>
            )}
            <CardContent>

                <p>Módulo de LeadCars para gestionar diferentes secciones y acciones para el funcionamiento de
                    LeadCars.</p>

                <p>A través de esta herramienta proveemos de información relativa a LeadCars para diferentes
                    departamentos sobre todo soporte y cuentas.</p>

                <h3>Clientes</h3>
                <p>Esta sección es para obtener información relativa a los clientes de LeadCars, sus concesionarios y
                    sedes.</p>

                <p>Tenemos información tanto en un listado como una vista individual.</p>

                <h3>Concesionarios</h3>
                <p>Listado de concesionarios.</p>

                <h3>Sedes</h3>
                <p>Listado de sedes.</p>

                <h3>InboxParser</h3>
                <p>Sección para configurar el destino de los parseos mediante la regla cliente_nombre en los
                    parseos.</p>

                <p>La creación de estos registros se efectuan con un selector para marcar cliente y concesionario de
                    manera requerida, y la opción de elegir sede.</p>

                <h3>Tipos Leads</h3>
                <p>Lista de tipos de leads con la opción de crear nuevos tipos incluso para tipos específicos por
                    clientes.</p>

                <h3>Categorías</h3>
                <p>Listar y crear nuevas categorías de Tipos de leads.</p>

                <h3>Campos dinámicos</h3>
                <p>Funcionalidad para renderizar diferentes datos obtenidos por los parseos.</p>

                <p>Estos campos tienen funcionalidades para diferentes servicios relacionados con campos dinámicos en
                    LeadCars (Informe de gestión de leads y reglas de automatización).</p>

                <h3>Log datos parseados</h3>
                <p>Lista de diferentes datos que al renderizar en LeadCars no encuentra su configuración bien en campos
                    a incluir o a excluir.</p>

                <h3>Campos incluidos</h3>
                <p>Lista de campos incluidos en el renderizado, opción de crear un campo nuevo.</p>

                <p>Al crear un nuevo campo incluido se selecciona el dato a incluir mediante un selector dentro del lod
                    de datos parseados, a configurar el nombre que se renderizará en la aplicación, el tipo de
                    renderizado y el grupo donde se va a renderizar el dato (Leads, Cliente, Vehículo).</p>

                <h3>Campos excluidos</h3>
                <p>Lista de datos a excluir en el renderizado.</p>

                <p>Aquí creamos campos que se excluiran a la hora de renderizar los datos de los leads.</p>

                <h3>Usuarios</h3>
                <p>Listar los usuarios de LeadCars.</p>

                <p>Aplicar diferentes acciones necesarias para la gestión relativa a los usuarios de LeadCars.</p>

                <h3>Estados</h3>
                <p>Listar los estados de LeadCars y su configuración derivada por clientes y roles de usuarios.</p>

            </CardContent>
        </Card>
    )

}

export default Dashboard;