import React from "react";
import {useMediaQuery} from '@mui/material';
import {
    ChipField,
    Datagrid,
    List,
    SimpleList,
    SingleFieldList,
    TextField,
    TextInput,
    ShowButton,
    SimpleForm,
    Edit,
    EditButton,
    ArrayField,
    SelectArrayInput, useRedirect, useRecordContext
} from "react-admin";
import {StringToLabelObject} from "./App";
import {apiClient} from "./Utils/Services/fetchService";

export const CategoriasLicenciasList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.nombre}
                    secondaryText={record => record.id}
                />
            ) : (
                <Datagrid>
                    <TextField source="id"/>
                    <TextField label="Nombres" source="name"/>
                    <TextField label="Estado" source="state.name"/>
                    <ArrayField source="roles">
                        <SingleFieldList>
                            <StringToLabelObject>
                                <ChipField source="label"/>
                            </StringToLabelObject>
                        </SingleFieldList>
                    </ArrayField>
                    <ShowButton/>
                    <EditButton/>
                </Datagrid>
            )}
        </List>
    );
}

const roles = [
    {id: 'ROLE_ADMIN', name: 'ROLE_ADMIN'},
    {id: 'ROLE_LEAD_MANAGER', name: 'ROLE_LEAD_MANAGER'},
    {id: 'ROLE_GERENTE', name: 'ROLE_GERENTE'},
    {id: 'ROLE_JV_PLUS', name: 'ROLE_JV_PLUS'},
    {id: 'ROLE_JEFE_VENTAS', name: 'ROLE_JEFE_VENTAS'},
    {id: 'ROLE_CONTACT_CENTER', name: 'ROLE_CONTACT_CENTER'},
    {id: 'ROLE_CONTROL_CALIDAD', name: 'ROLE_CONTROL_CALIDAD'},
    {id: 'ROLE_COMERCIAL', name: 'ROLE_COMERCIAL'}
];

export const CategoriasLicenciasEdit = props => {

    const record = useRecordContext();

    const redirect = useRedirect();

    const handleConfirm = async (data) => {

        const urlAPI = `${process.env.REACT_APP_API_URL}lt`;

        const params = {id: data.id, roles: data.roles};

        let response = await apiClient.put(`${urlAPI}/categoriasLicencias/${data.id}`, {
            method: 'PUT',
            body: JSON.stringify(params)
        }).then(response => response.json());

        if (response) {
            redirect('/clientes/' + response.id + '/show');
        }
    };

    return (
        <>
            <Edit title="Licencias">
                <SimpleForm onSubmit={handleConfirm} initialValues={record}>
                    <TextInput
                        source="id"
                        label="ID"
                        InputProps={{readOnly: true}}
                    />
                    <TextInput
                        source="idLicense"
                        label="ID Cliente"
                        InputProps={{readOnly: true}}
                    />
                    <TextInput
                        source="name"
                        label="Cliente"
                        InputProps={{readOnly: true}}
                    />
                    <TextInput
                        label="Estado"
                        source="state.name"
                        InputProps={{readOnly: true}}
                    />

                    <SelectArrayInput source="roles" label="Roles" choices={roles}/>
                </SimpleForm>
            </Edit>
        </>
    );
};